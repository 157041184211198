body.team {

section.person{
	padding-top: 30px;
	padding-bottom: 30px;
	display: flex;
	flex-direction: column;
	@media screen and (min-width: 800px) {
		flex-direction: row;
	}
	div.img-wp{
		
		div.img{
			width: 100%;
			height: 100%;
			display: flex;
			justify-content: center;
			img{
				width: 100vw;
				height: 100vw;
				max-width: 350px;
				max-height: 350px;
				padding: 0px 10px;
				margin-left: 2px;
				object-fit: cover;
				border-radius: 100%;
			}
		}
	}

	div.name-pos{
		padding-top: 10px;
		width: 100%;
		height: 100%;
		div.name{
			color: #FFF;
			//background: var(--col-accent-1);
			background: #0d5287;
			font-weight: bold;
			font-size: 1.3rem;
			height: 45px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
		div.position{
			padding-top: 5px;
			text-align: center;
			font-style: italic;
		}
	}
	div.text{
	    height: 100%;
	    margin: 40px 0 40px 0;
	    display: flex;
	    align-items: center;
	    padding: 0 40px 0 40px;
	    text-align: justify;
	}
	div.social{
		padding-top: 10px;
		display: flex;
		//justify-content: center;
		a{
			width: 100%;
			display: flex;
			align-items: center;
			padding: 0px 15px;
			color: #555;
			font-size: 1.1rem;
			font-weight: bold;
			img{
				margin-right: 8px;
			}
		}
	}
}
section.person:not(:last-child){
	border-bottom: 1px #CCC solid;
}
@media screen and (min-width: 800px) {
	section.person.right {
		flex-direction: row-reverse;
	}
	div.text{
		min-height: 200px;
	}
}
}
