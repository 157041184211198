section.measure{
	background: #000;
	position: relative;
	text-align: center;
	color: #FFF;
	height: 600px;
	div.background {
		position: absolute;
		height: 100%;
		width: 100%;

		video, img{
			position: absolute;
			width: 100%;
			height: 100%;
			left: 0px;
			object-fit: cover;
			animation-duration: var(--trans-dur);
			animation-iteration-count: infinite;
		}
	}
	div.shadow{
		width: 100%;
		height: 100%;
		
    position: relative;
    box-shadow: inset 0px 0px 180px 10px #000;
	
		> div.wrapper{
			position: relative;
			height: 100%;
			padding: 0px;
    	text-shadow: 0px 0px 10px #000;

			div.bars{
				padding-top: 90px;
				display: flex;
				flex-direction: row;
				justify-content: space-evenly;

				> div{
					div.bar{
						position: relative;
						height: 180px;
						img{
							position: absolute;
							left: 0px;
							right: 0px;
							margin: 0px auto;
							height: 100%;
							animation-duration: var(--trans-dur);
							animation-iteration-count: infinite;
						}
					}
					div.title{
						padding-top: 3px;
						font-size: 1.2rem;
						font-weight: bold;
					}
					div.subtitle{
						font-size: 0.9rem;
						color: #CCC;
					}
				}
			}

			> div.subtitle{
				position: absolute;
				left: 0px;
				width: 100%;
				bottom: 55px;
				padding: 50px 10px 30px 10px;
				box-sizing: border-box;
				
				div.slogan{
					font-size: 1.7rem;
				}
				div.example{
					padding-top: 25px;
					span{
						position: absolute;
						width: 100%;
						left: 0px;
						bottom: -15px;
						font-size: 1.7rem;
						font-weight: bold;
						animation-duration: var(--trans-dur);
						animation-iteration-count: infinite;
					}
					
				}
			}
		}
	}
}
