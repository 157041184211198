section.features {
	> div.title{
		width: 100%;
		color: #444;
		text-align: center;
		font-size: 3rem;
		padding: 45px 0px 20px 0px;
	}
	div.wrapper {
		max-width: 600px;
		display: flex;
		flex-direction: column;
		div.feature{
			display: flex;
			flex-direction: row;
			height: 80px;
			padding-top: 20px;
			padding-bottom: 8px;
			img{
				height: 100%;
				width: 100px;
				object-fit: contain;
				box-sizing: border-box;
				padding: 10px;
				padding-right: 20px;
			}
			div.text{
				display: flex;
				flex-direction: column;
				justify-content: center;
				div.title{
					font-size: 1.2rem;
					font-weight: bold;
					color: #444;
				}
				div.specs{
				}
			}
		}
		div.feature.long{
			transition: opacity 0.5s, height 1s, padding 1s;
			opacity: 0;
			height: 0px;
			padding-top: 0px;
			padding-bottom: 0px;
		}
		div.feature:not(:first-child){
			border-top: 1px solid #CCC;
		}
		> div.title{
			font-size: 1.6rem;
			padding-left: 5px;
			//font-weight: bold;
			opacity: 0;
			height: 0px;
			transition: opacity 0.5s, height 1s, padding-top 1s;
		}
	}
	div.show-details{
		text-align: center;
		position: relative;
		padding: 10px 30px 10px 0px;
		cursor: pointer;
	}
	div.show-details:after{
		content: "";
		width: 0;
		height: 0;
		position: absolute;
		bottom: 15px;
		margin-left: 5px;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;

		//border-top: 16px * 0.866 solid #888;
		border-top: 8px solid #888;
		transition: transform 1s;
	}
	span.long{
		display: none;
	}


}
section.features.details{
	div.show-details:after{
		transform: rotate(180deg);
	}
	span.short{
		display: none;
	}
	span.long{
		display: initial;
	}
	div.wrapper {
		div.feature.long{
			opacity: 1;
			height: 80px;
			padding-top: 20px;
			padding-bottom: 8px;
		}
		> div.title.long{
			opacity: 1;
			height: 30px;
			padding-top: 20px;
		}
	}
}
