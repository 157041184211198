section.connect{

	div.table{
		display: flex;
		flex-direction: column;
		align-items: center;

		div.product-img {
			//max-height: 250px;
			img {
				width: 100%;
				max-width: 800px;
				height: 100%;
				object-fit: contain;
			}
		}
		a.price-buy{
			margin-top: 20px;
		}
		div.social {
			padding-top: 40px;
			margin-top: 150px;
			display: flex;
			flex-direction: row;
			justify-content: center;
			a {
				padding: 0px 15px;
			}
			padding-bottom: 20px;
		}
		div.mail {
			padding-bottom: 30px;
			margin-bottom: 150px;
			color: #888;
		}
		div.team {
			padding-bottom: 30px;
			a{
				color: #404040;
				font-size: 1.5rem;
				text-decoration: underline;
				//text-transform: uppercase;
			}
		}
		div.newsletter-wp{
			padding-top: 20px;
			padding-bottom: 30px;
			background: #EFEFEF;
			width: 100%;
			form.newsletter{
				width: 100%;
				max-width: 400px;
				margin: 0px auto;
				display: flex;
				flex-direction: column;
				box-sizing: border-box;
				padding: 0px 20px;
				div.datenschutz{
					display: none;
				}
				input[type="checkbox"]{
					margin-top: 15px;
				}
				input[type="submit"], input[type="email"] {
					margin-top: 10px;
					width: 100%;
				}
				input[type="email"]{
					background: #EFEFEF;
				}
				input[type="submit"]{
					width: 100%;
					border:2px solid var(--col-accent-1);
					background: none;
					text-transform: uppercase;
					//font-weight: bold;
					background: var(--col-accent-1);
					color: #FFF;
					cursor: pointer;
				}
			}
			form.newsletter.active{
				div.datenschutz{
					display: block;
				}
			}
		}
	}
}
