body.loading{
	div.loading{
		display: flex;
	}
}

div.loading{
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 10000;
	background: #0005;
	display: none;
	justify-content: center;
	align-items: center;

		.lds-ripple {
		  display: inline-block;
		  position: relative;
		  width: 200px;
		  height: 200px;
		}
		.lds-ripple div {
		  position: absolute;
		  border: 4px solid #fff;
		  opacity: 1;
		  border-radius: 50%;
		  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
		}
		.lds-ripple div:nth-child(2) {
		  animation-delay: -0.5s;
		}
		@keyframes lds-ripple {
		  0% {
		    top: 87px;
		    left: 87px;
		    width: 0;
		    height: 0;
		    opacity: 1;
		  }
		  100% {
		    top: -1px;
		    left: -1px;
		    width: 181px;
		    height: 181px;
		    opacity: 0;
		  }
		}
}
