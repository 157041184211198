:root{
	--header-height: 55px;
	--switch-height: 75px;
	--col-accent-1: #42a1ec;
}

@import 'defaults';
@import 'header';
@import 'product';
@import 'feinstaub';
@import 'measure';
@import 'app';
@import 'base-color';
@import 'features';
@import 'connect';
@import 'footer';

@import 'buy';
@import 'team';
@import 'verify';
@import 'loading';

