section.base-color{
	background: #e8e8e8;
	color: #000;
	div.wrapper{
		div.text{
			text-align: center;
			div.title{
				padding-top: 30px;
				font-size: 2.5rem;
				color: #222;
			}
			div.subtitle{
				padding-top: 5px;
				color: #555;
			}
		}
	}
	div.base{
		display: flex;
		flex-direction: column;
		align-items: center;
		overflow: hidden;

		div.base-img{
			margin-top: 20px;
			//max-height: 600px;
			width: 130%;
			max-width: 1400px;
			img {
				height: auto;
				width: 100%;
			}
		}
		/*
		div.label{
			margin-top: -70px;
			//width: 150px;
			height: 40px;
			border: 3px solid var(--col-accent-1);
			//background: #FFF;
			padding: 0px 10px;
			display: flex;
			justify-content: center;
			align-items: center;

			color: #444;
			//text-transform: uppercase;
			font-size: 0.9rem;
			//font-weight: bold;
		}
		*/
		//padding-bottom: 30px;
	}
	
}
