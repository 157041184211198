section.product {
	position: relative;
	background:#f1f1f1;
	z-index: 0;
	height: calc(100vh - var(--header-height) - 50px);
	/* fixes annoying mobile address bar*/
	height: calc(var(--vh, 1vh) * 100 - var(--header-height) - var(--switch-height));

	min-height: 400px;
	color: #333;
	div.back-img{
		position:absolute;
		z-index: -1;
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: center;
		overflow: hidden;

		img {
			height: 100%;
			//width: auto;
			width: 120%;
			margin-top: -60px;
			@media screen and (min-width: 700px) {
				margin-top: -100px;
			}
			object-fit: contain;
		}
	}
	img.badge{  
		position: absolute;
		//margin-right: 60px;
		//float: right;
		//margin-top: 420px;
		right: 0px;
		bottom: 35%;
		width: 100px;
		height: 100px;

		@media only screen and (min-width: 800px) {
			width: 180px;
			//bottom: 30%;
			//left: 750px;
			height: 180px;
		}
	}
	div.info {
		position: absolute;
		width: 100%;
		max-width: 500px;
		left: 0px;
		right: 0px;
		margin: 0px auto;
		padding: 0px 10px;
		box-sizing: border-box;
		//top: calc(var(--header-height) )
		bottom: 20px;

		@media screen and (min-height: 600px) {
			bottom: 8%;
		}

		display: flex;
		flex-direction: column;
		justify-content: space-between;
		div.table{
			width: 100%;
			display: flex;
			flex-direction: column;
			align-items: center;

			div.company{
				display: flex;
				flex-direction: row;
				justify-content: center;
				align-items: flex-end;
				div.logo {
					width: 120px;
					margin-bottom: -6px;
					img{
						width: 100%;
					}
				}
				div.airtracker{
					//padding-top: 10px;
					padding-left: 8px;
					font-size: 1.8rem;
					//font-weight: bold;

				}
				margin-bottom: 20px;
			}
			div.sensor{
				margin-bottom: 20px;
				font-size: 1.5rem;
				//color: #FFF;
			}
		}
	}
}
