.content-card{
	font-size: 1.3rem;
	.card-wp {
		padding-top: 15px;
		max-width: 1000px;
		margin: 0px auto;
	}
	.card > * {
		padding: 20px 5vw;
		padding-bottom: 0px;
		margin: 0px;
		width: auto;
	}

	.card > *:last-child{
		padding-bottom: 25px;
		/* padding-top: 20px; */
	}
	.all-unsub-wp {
		padding: 5vw 5vw;
	}
	.all-unsub-wp > div {
		display: flex;
		flex-direction: row;
		justify-content: center;
		align-items: center;
		border: 5px solid var(--col-blue-2);
		border-radius: 40px;
		background: #e5f2ff;
	}
	.all-unsub-wp img {
		height: 100px;
	}
	.all-unsub-wp h2 {
		font-size: 3.5rem;
		padding-left: 20px;
		/* font-weight: normal; */
		margin: 30px 0px;
	}

	.card h1 {
		margin: 0px;
		padding-top: 50px;
	}

	.card table{
		width: 100%;
		border-spacing: 0px;
	}
	.card table th{
		border-bottom: 1px solid #ccc;
		padding-bottom: 5px !important;
	}
	.card table th.disabled {
		color: #ccc;
	}
	.card table td:not(:first-child){
		text-align: center;
	}
	.card table td, .card table th{
		padding: 15px 20px;
	}
	.card td img {
		height: 1.5rem;
	}
	.card .saved-suc {
		border: 2px solid var(--col-blue-2);
		background: #e5f2ff;
		border-radius: 10px;
		height: fit-content;
		display: flex;
		align-items: center;
		padding: 0px 10px;
	}
	.card .saved-suc *{
		padding: 5px 5px;
		display: block;
	}
	.card .saved-suc img{
		height: 2rem;
	}

	@media (max-width: 610px){
		.all-unsub-wp img {
			height: 50px;
		}
		.all-unsub-wp h2 {
			font-size: 1.5rem;
			padding-left: 20px;
		}
	}
}
