section.feinstaub{
	height: 80vh;
	max-height: 800px;
	position: relative;
	color: #333;
	video, div#particles {
		position: absolute;
		object-fit: cover;
		height: 100%;
		width: 100%;
		background-color: #2677b7;
	}
	
	div.wrapper{
	    color: white;
		max-width: unset;
		//only if video is active
		//box-shadow: inset 1px 20px 80px 14px #000;
		//box-shadow: inset 0px 0px 20px 0px #000;
		//border: 2px solid #000;
		//border-top: 1px solid #CCC;
		padding: 0px;
		box-sizing: border-box;
		position: relative;
		z-index: 1;
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		text-align: center;
		div.top{
			position: absolute;
			top: 20%;
			width: 100%;
			div.feinstaub{

				font-size: 3.6rem;
				@media screen and (min-width: 1000px) {
					font-size: 6rem;
				}
				font-weight: bold;
				text-transform: uppercase;
			}

			div.danger{
				font-size: 1.1rem;
			}
		}

		div.question{
			position: absolute;
			bottom: 15%;
			width: 100%;
			left: 0px;
			padding: 0px 10px;
			text-align: center;
			box-sizing: border-box;
			font-size: 1.5rem;
		}
	}
}
