body.buy{
	background: #F8F8F8;

div.product-title{
	width: 100%;
	background: #F0F0F0;
	//padding-top: 40px;
	img{
		width: 100%;
		max-height: 50vh;
		object-fit: contain;
	}
	border-bottom: 1px solid #888;
}
div.delay-warning{
	min-height: 50px;
	overflow: hidden;
	padding: 10px 10px;
	box-sizing: border-box;
	background: var(--col-accent-1);
	color: #FFF;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	transition: height 1s 2s, min-height 1s 2s, padding 1s 2s;
	div{
		position: absolute;
		height: auto;
		overflow: hidden;
		opacity: 1;
		transition: height 1s 2s, opacity 1s 2s;
	}
}
div.delay-warning.hidden{
	min-height: 0px;
	height: 0px;
	padding: 0px;
	div{
		height: 0px;
		opacity: 0;
	}
}
div.content{
	min-height: initial !important;
	max-width: 600px;
	margin: 0px auto;
	display: flex;
  flex-direction: column;
	form.switcher {
		display: flex;
		flex-direction: row;
		width: 300%;
		transition: margin-left 0.5s;
		section{
			width: 100%;
			div.title{
				font-size: 1.5rem;
				color: #444;
				padding: 15px 0px 5px 15px;
			}
		}

		section.configure{
			//margin-left: -50%;
			div.card {
				padding: 20px;
				box-sizing: border-box;
				div.option{
					background: #FFF;
					padding: 10px;
					border: 1px solid #CCC;
					box-shadow: 0px 0px 10px #0001;
					border-radius: 10px;
					display: flex;
					flex-direction: row;
					align-items: center;
					input{
						width: 40px;
					}
					div.text{
						width: 100%;
						div.title{
							color: #444;
							padding-top: 5px;
							font-weight: bold;
							padding-left: 10px;
							padding-right: 25px;
							font-size: 1.15rem;
							span.price{
								font-size: 1.3rem;
								padding-left: 5px;
								float: right;
							}
						}
						ul{
							margin-top: 5px;
							margin-bottom: 0px;
							padding-left: 25px;
						}
					}
				}
			}
		}
		section.total, section.agree, section.shipping{
			display: none;
		}
		div.input{
			padding: 20px;
			padding-bottom: 0px;
			input{
				background: #F8F8F8;
			}
			input[type="text"], input[type="email"], input[type="number"]{
				width: 100%;
				margin-bottom: 20px;
			}
			input[type="text"]:disabled, input[type="email"]:disabled, input[type="number"]:disabled{
				color: #AAA;
			}
			div.row{
				display: flex;
				justify-content: space-between;
				>*:first-child {
					width: 35%;
				}
				>*:last-child {
					width: 60%;
				}
			}
		}
	}
	div.error{
		//font-weight: bold;
		color: red;
		padding: 0px 20px;
	}
	button.next, button.paypal{
		margin-top: 30px;
		width: 150px;
		width: 100%;
		height: 50px;
		border: none;	
		background: var(--col-accent-1);
		float: right;
		color: #FFF;
		display: flex;
		align-items: center;
		justify-content: space-evenly;
		text-transform: uppercase;
		font-weight: bold;
		position: relative;
		> div{
		}
		> div:after{
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			bottom: 16px;
			right: 20px;
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;

			border-left: 16px solid #FFF;
			transition: transform 1s;
		}
	}
	button.paypal{
		display: none;
	}
	div.back{
		margin-top: 10px;
		text-align: center;
		margin-bottom: 100px;
		text-decoration:underline;
		color: #888;
	}
}
div.content.list {
	form.switcher {
		flex-direction: column;
		width: 100%;
		section.total{
			padding-top: 20px;
			display: flex;
			flex-direction: column;
			width: 100%;
			> div{
				display: flex;
				width: 100%;
				padding: 10px 15px;
				box-sizing: border-box;
				justify-content: space-between;
				flex-direction: row;
			}
			div.price{
				border-top: 1px solid #AAA;
			}
			div.total{
				border-top: 2px solid #333;
				font-weight: bold;
				span.MwSt{
					color: #888;
					font-size: 0.8rem;
				}
				div.total-sum{
					color: #333;
					font-size: 1.2rem;
				}
			}
		}
		section.agree{
			display: flex;
			flex-direction: column;
			padding: 15px 10px 0px 10px;
			box-sizing: border-box;
			>div {
				display: flex;
				flex-direction: row;
				align-items: center;
				font-size: 0.8rem;
				padding-top: 15px;
				>label{
					padding-left: 5px;
					input{
						margin-right: 10px;
					}
				}
			}
		}
		section.shipping{
			border-top: 1px solid #AAA;
			padding: 15px 10px 0px 10px;
			margin-top: 20px;
			box-sizing: border-box;
			display: flex;
			flex-direction: column;
			>div{
				display: flex;
				padding: 0px;
				flex-direction: row;
				justify-content: space-between;
				align-items: center;
				div.title{
					padding: 0px;
					font-size: 1.2rem;
					font-weight: bold;
				}
			}
			div.conditions{
				padding-top: 20px;
			}
		}
	}
	button.next{
		display: none;
	}
	button.paypal{
		display: block;
	}
}

}
