body{
	margin: 0;
	min-width: 400px;
	background-color: #FFF;
	//background-color: #333;
	color: #666;
	font-family: "alwyn-new-web", "Arial";
}
.wrapper{
	padding-top: var(--header-height);
	.content{
		min-height: calc(100vh - 30px - var(--header-height));
		overflow: hidden;
	}
}
section > div.wrapper, section > div.shadow > div.wrapper{
	width: 100%;
	max-width: 1000px;
	margin: 0px auto;
	padding: 0px 10px;
	box-sizing: border-box;
}
div.max-width{
	width: 100%;
	max-width: 1000px;
	margin: 0px auto;
	//padding: 0px 10px;
	box-sizing: border-box;
}
img{
	height: 100%;
}
a{
	text-decoration: none;
}

:root{
	--trans-dur: 18s;//3x6s
}

a.price-buy {
	display: block;
	width: 300px;
	height: 60px;
	background: var(--col-accent-1);
	
	color: #FFF;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	span.buy{
		display: block;
		font-size: 1.1rem;
		text-transform: uppercase;
	}
	//font-weight: bold;
	span.price{
		display: block;
		font-size: 1.8rem;
		font-weight: bold;
	}
}
img.social-icon{
	width: 40px;
	height: 40px;
}
input {
	margin: 0px;
	padding: 10px 5px;
	box-sizing: border-box;
	border: 0px;
	font-size: 1.1rem;
}
input[type="text"],input[type="email"], input[type="number"]{
	border-bottom: 2px solid var(--col-accent-1);
	padding-bottom: 1px;
	color: #444;
	background: #EFEFEF;
}
div.MWS{
	padding-top: 5px;
	color: #666;
	width: 100%;
	text-align: center;
}


div.title-img-back{
	position: relative;
	width: 100%;
	display: flex;
	justify-content: center;
	background: #F0F0F0;
	//padding-top: 40px;
	img{
		width: 100%;
		height: 50vh;
		object-fit: cover;
		object-position: 50% 0%;
	}
	border-bottom: 1px solid #888;
}
div.title-over-img{
	position: absolute;
	width: 100%;
	font-size: 5rem;
	bottom: 40px;
	//height: 18vh;
	color: #FFF;
	text-align: center;
	text-shadow: 0px 0px 30px #000;
}
span.large{
	font-size: 3em;
	font-weight: bold;
}

.time-1{animation-name: img1;}
.time-2{animation-name: img2;}
.time-3{animation-name: img3;}
.time-1_{animation-name: img1_;}
.time-2_{animation-name: img2_;}
.time-3_{animation-name: img3_;}

@keyframes img1 {
	0% {opacity: 0;}
	10% {opacity: 1;}
	30% {opacity: 1;}
	40% {opacity: 0;}
	100% {opacity: 0;}
}
@keyframes img2 {
	0% {opacity: 0;}
	35% {opacity: 0;}
	45% {opacity: 1;}
	65% {opacity: 1;}
	75% {opacity: 0;}
	100% {opacity: 0;}
}
@keyframes img3 {
	5% {opacity: 0;}
	70% {opacity: 0;}
	80% {opacity: 1;}
	95% {opacity: 1;}
}

@keyframes img1_ {
	0% {opacity: 0;}
	10% {opacity: 1;}
	40% {opacity: 1;}
	45% {opacity: 0;}
	100% {opacity: 0;}
}
@keyframes img2_ {
	0% {opacity: 0;}
	40% {opacity: 0;}
	45% {opacity: 1;}
	80% {opacity: 1;}
	85% {opacity: 0;}
	100% {opacity: 0;}
}
@keyframes img3_ {
	5% {opacity: 1;}
	10% {opacity: 0;}
	75% {opacity: 0;}
	80% {opacity: 1;}
	100% {opacity: 1;}
}
