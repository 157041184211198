header{
	height: var(--header-height);
	background: #FFF;
	z-index: 99;
	width: 100%;
	position: fixed;
	display: flex;
	justify-content: space-between;
	align-items: center;
	top: 0px;
	box-shadow: 0px 0px 10px #0002;

	//border-bottom: 1px solid #CCC;

	.logo {
		height: 100%;
		padding: 5px 10px;
		box-sizing: border-box;
			img{
			height: 100%;
		}
	}
	div.buy-wp{
		height: 100%;
		//padding: 8px 10px;
		box-sizing: border-box;
		div.buy{
			height: 100%;
			display:flex;
			background: var(--col-accent-1);
			//border-radius: 5px;
			padding: 0px 8px;
			align-items: center;
			img{
				width: 50px;
				filter: invert(1);
				height: auto;
			}
			.text{
				padding-left: 12px;
				color: #FFF;
				font-weight: bold;
				text-transform: uppercase;
			}
		}
	}
	div.header_title{
		height: min-content;
		font-size: 2rem;
		width: 100%;
		text-align: center;
		color: #333;
	}
}

div.switch-header{
	width: 100%;
	//margin-top: var(--header-height);
	height: var(--switch-height);
	.switch{
		display: flex;
		width: 100%;
		height: 100%;

		font-size: 1.4rem;

		> a {
			color: #AAA;
			width: 50%;
			height: 100%;
			text-align: center;
			display: flex;
			align-items: center;
			justify-content: center;
			box-sizing:border-box;

			border-bottom: 6px transparent solid;
			img{
				height: 20px;
				filter: contrast(0);
			}
			div {
				padding-left: 15px;
			}
		}
		> div:hover{
			background: #F0F0F0;
		}
		a.active{
			border-bottom-color: var(--col-accent-1);
			color: #555;
			//background: var(--col-accent-1);
			//border: 1px solid var(--col-accent-1);
			background: #F4F4F4;
			//font-weight: 100;
			img{
				filter: contrast(1);
			}

		}
		> div:not(.active){
			//background: #EEE;
		}
	}
}
