section.app-connections{
	div.connection-img{
		min-height: 150px;
		background: #FFF;
		width: 100%;
		padding: 40px 20px;
		box-sizing: border-box;
		display: flex;
		align-items: center;
		img{
			width: 100%;
			max-height: 200px;
			object-fit: contain;
		}
	}
}
section.app{
	background: #2677b7;
	//background: linear-gradient(45deg, #2d3b4d, #97c2f7);

	div.title{
		padding-top: 30px;
		width: 100%;
		font-size: 2.5rem;
		text-align: center;
		color: #FFF;
		padding-bottom: 10px;
	}
	div.imgs{
		position: relative;
		width: auto;
		height: 500px;
		display: flex;
		margin: 0px auto;
		
		div{
			position: absolute;
			height: 100%;
			width:100%;
			animation-duration: var(--trans-dur);
			animation-iteration-count: infinite;

			display: flex;
			justify-content: center;
			img{
				height: 100%;
			}
			//height: auto;
		}
	}
	/*  -- App inklusive rechts ---
	@media screen and (min-width: 800px) {
		display: flex;
		flex-direction: row-reverse;
		justify-content:center;
		div.title{
			width: auto;
			height: min-content;
			padding: 150px 10px 2px 10px;
			margin-left: 40px;
			font-size: 3rem;
			border-bottom: 1px solid #FFF;
		}
		div.imgs{
			margin: 0px;
			width: 350px;
		}
	}
	*/
	@media screen and (min-width: 1400px) {
		div.imgs{
			justify-content: center;
			> div{
				position: relative;
				width: auto;
				animation: none;
			}
		}
	}
}
